.logout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: calc(100vh - 160px);
  padding: 0 100px;
}

.logout h1 {
  color: #0066ff;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  margin: 40px 0 0;
}

.logout h2 {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #04142c;
  margin: 52px 0 0;
}

.logout h3 {
  color: #04142c;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 32px 0 0;
}
