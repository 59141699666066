html * {
  font-family: 'Inter', sans-serif !important;
}

html,
body {
  height: 100%;
  background-color: var(--MirNeutral02) !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --MirNeutral01: #ffffff;
  --MirNeutral02: #f6f9fb;
  --MirNeutral03: #e9ecf0;
  --MirNeutral04: #e0e0e0;
  --MirNeutral05: #bcbcbc;
  --MirNeutral06: #707070;
  --MirNeutral07: #616161;
  --MirNeutral08: #252525;
  --MirNeutral09: #bbddff;

  --MirPrimary01: #0066ff;
  --MirPrimary02: #0c387a;
  --MirPrimary03: #04142c;

  --MirSc01: #ff6565;
  --MirSc02: #e1c686;
  --MirSc03: #153759;
  --MirSc04: #9caebe;

  --MirPositive01: #bff7de;
  --MirPositive02: #a5d6a7;
  --MirPositive03: #43a047;

  --MirNegative01: #ffd0cc;
  --MirNegative02: #ef9a9a;
  --MirNegative03: #e53935;

  --MirWarning01: #ffe8c6;
  --MirWarning02: #ffe082;
  --MirWarning03: #ffa000;

  --MirInfo01: #cae3fd;
  --MirInfo02: #81d4fa;
  --MirInfo03: #0288d1;
}
