.App {
  background-color: var(--MirNeutral02);
  background-image: url('./assets/bkgImg.svg');
  background-repeat: no-repeat;
  background-position-x: 5%;
  background-position-y: 70px;
  background-size: cover;
}

*::-webkit-scrollbar {
  width: 20px;
}

*::-webkit-scrollbar {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
