/* DROPZONE STYLES -----------------------------------------------------------------------*/
.dropzone-ui-extra {
  border: none !important;
  height: 100% !important;
  max-height: 100% !important;
  min-height: 10px !important;
  padding: 0px 20px;
}

.dz-ui-footer {
  display: none !important;
  width: 50% !important;
  align-items: initial !important;
  padding: 0 !important;
  border-top: none !important;
  border-radius: 4em;
}

.dz-ui-header {
  margin-top: 10px !important;
}

.dz-ui-label {
  font-size: 18px !important;
  font-family: 'Inter', sans-serif !important;
  letter-spacing: 0 !important;
}
.dz-ui-label::after {
  content: 'browse';
  color: var(--MirPrimary01);
}

.file-item-full-container-container .file-item-icon-container .img-container img {
  width: 80px !important;
}

.file-item-full-container-container .file-item-icon-container {
  height: 90px !important;
  width: 90px !important;
}

.file-item-full-container-container .file-item-name {
  font-size: 12px !important;
  width: 93px !important;
}

.file-item-full-container-container {
  width: 130px !important;
}

.dropzone-ui.clickable {
  background-color: var(--MirNeutral03) !important;
}

.pre-wrap-list-item {
  white-space: pre-wrap;
  overflow: hidden;
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: var(--MirNeutral05);
}

/* ALL SECTIONS--------------------------------------------------------------------*/
/* Upload and download buttons */
.buttons_container {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.buttons__text {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.08em;
  margin: 0px;
}

/* Titles*/
h2 {
  color: var(--MirPrimary01);
  font-size: 24px;
  font-weight: 600;
  margin: 0px;
}

/* Descriptions */
.filetype__text {
  display: inline-block;
  color: var(--MirPrimary01);
  font-size: 18px !important;
  font-weight: 600 !important;
}

/* File type img */
.file__img {
  height: 32px;
  width: 28px;
  margin: 0px 10px -10px 10px;
  display: inline-block;
}

/* FILE LISTS --------------------------------------------------------------------------*/
.list__container {
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 30px;
  width: 100%;
  padding: 0px;
  min-height: calc(100% - 225px);
}

.listItem__name {
  overflow: hidden !important;
}

.file-item-list-container.file-item-list-no-scroll {
  overflow: scroll !important;
  min-height: 80px !important;
}

.Mui-checked {
  color: var(--MirPrimary01) !important;
}

/* OVERWRITE MODAL --------------------------------------------------------------------*/
.closeBtnOW__container {
  display: flex;
  justify-content: flex-end;
}

.bodyOW__container {
  display: grid;
  justify-items: center;
  align-items: center;
  align-content: space-between;
  height: calc(100% - 35px);
  padding: 0px 16px 16px 16px;
}

.bodyOW__container p {
  margin: 0;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: inline-block;
}

.bodyOW__container .filenameOW {
  color: var(--MirPrimary01);
  font-weight: 600;
}

.buttonsOW__container {
  display: flex;
  width: 300px;
  justify-content: space-between;
}

.buttonsOW__container p {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

/* PREVIEW SECTION----------------------------------------------------------------------*/
.preview__container {
  background-color: var(--MirNeutral01);
  height: calc(100% - 70px);
  width: 90%;
  padding: 10px 10px;
  margin: 40px auto;
}

.closePreview__container {
  display: flex;
  justify-content: flex-end;
}

.bodyPreview__container {
  padding: 0px 18px 40px 18px;
  height: calc(100% - 35px);
}

.filename__preview {
  font-size: 16px;
  font-weight: 600;
  margin: 28px 0px 18px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.circularProgress__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
}

.table__container {
  height: calc(100% - 96px);
  overflow-y: scroll;
}

/* OTHERS -----------------------------------------------------------------------------*/
.emptyMsgAndLoading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  min-height: 300px;
}
