.titleContainer {
  margin-top: 40px;
  text-align: center;
  width: 100%;
}

.errorContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 160px);
}
